import (/*webpackChunkName: 'swiper'*/ '../SwiperSrc/swiper.esm')
  .then((asd) => {
    window.Swiper = asd.Swiper;
    const nav = asd.Navigation;
    window.Swiper.use([nav]);
  })
  .then(() => {
    app.mySwiper = new Swiper (
      '.swiper-container', 
      {
        slidesPerView: 1,
        spaceBetween: 25,
        breakpoints: {
          652: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
        },

        loop: true,
        preloadImages: false,
        lazy: {
            loadPrevNext: true,
        },
        navigation: {
            nextEl: '.slider-next-js',
            prevEl: '.slider-prev-js',
        },
        grabCursor: true,
        on: {
            slideChange: function() {
                if (typeof(app.LazyLoad) !== 'undefined') { 
                    app.LazyLoad.update();
                }
            },
        }
      }
    )
  });
