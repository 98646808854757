//Targeting Google analitycs

var elm, tag_name, elm_type;
$(document).on('popup_opened.social-likes', function(e){
    var gaElm = $(e.target);
    app.fn.triggerGaEvent(gaElm.data());
});

$(document).on('click', '.gtarget-js', function(e){
    var gaElm = $(this);
    app.fn.triggerGaEvent(gaElm.data());
});

//for calls which reloads pages
//trigger primary action if present
var gaDomElm = $('.gtarget-success-js');
if (gaDomElm.length) {
    if (typeof(gaDomElm.data('gcat-primary')) !== 'undefined' && typeof(gaDomElm.data('gact-primary')) !== 'undefined') {
        var gaEventData = {
            gcat: gaDomElm.data('gcat-primary'),
            gact: gaDomElm.data('gact-primary'),
        };
        if (typeof(gaDomElm.data('gact-primary-label')) !== 'undefined') {
            gaEventData['glabel'] = gaDomElm.data('gact-primary-label');
        }
        if (typeof(gaDomElm.data('gcat')) === 'undefined') {
            gaEvent['callback'] = function() {gaDomElm.remove();};
        }
        app.fn.triggerGaEvent(gaEventData);
    }
}
//if element is still exists (they have common action)
//trigger common action
gaDomElm = $('.gtarget-success-js');
if (gaDomElm.length) {
    app.fn.triggerGaEvent($.extend(
        gaDomElm.data(),
        {callback: function() {gaDomElm.remove();}}
    ));

}
//Targeting Google analitycs