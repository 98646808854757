export default function () {
    let wrapperTags = $('.blog-left-paneled .topics-js');

    if (!wrapperTags.length) {
        return;
    }

    let listTags = wrapperTags.find('.tags-js');
    let moreBtn = wrapperTags.find('.more-tags-js');
    let lessBtn = wrapperTags.find('.less-tags-js');
    let page = 1;

    let getMore = function() {
        let reqUrl = new URL(location.origin + '/blogs/get_more_topics');
        page++;
        reqUrl.searchParams.set('page', page);
        return $.ajax({
            url: reqUrl.toString(),
            method: 'GET',
            data: {}
        }).then(function(msg) {
            if (msg.error) {
                app.fn.alert({title: err.error, message: msg.error});
            } else if (!msg.html || !msg.countPages) {
                app.fn.alert({title: err.error, message: err.err_format});
            } else {
                return msg;
            }
        });
    }

    let markNewTagsActive = function() {
        let links = listTags.find('a');
        links.each(function() {
            let l = location.href.replace(location.search, '');
            let r = $(this)[0].href;
            if (l === r) {
                $(this).addClass('active');
                return false;
            }
        });
    }

    moreBtn.on('click', function(e) {
        e.preventDefault();
        getMore()
        .then(function(msg) {
            if (msg){
                listTags.append(msg.html);
                markNewTagsActive();
                if (page >= msg.countPages) {
                    moreBtn.removeClass('active');
                    lessBtn.addClass('active');
                } else {
                    moreBtn.addClass('active');
                    lessBtn.removeClass('active');
                }
            }
        });
    });

    lessBtn.on('click', function(e) {
        e.preventDefault();
        let links = listTags.find('li');
        links.each(function(i, v) {
            if (i >= 4) {
                v.remove();
            }
        });
        moreBtn.addClass('active');
        lessBtn.removeClass('active');
        page = 1;
    });

};
