//app.templates['GooglePlayInvite'] = '//inject:js/src/googlePlayInvite/googlePlayInvite.html//endinject';
app.templates['GooglePlayInvite'] = require('./googlePlayInvite.html').default;

export default function () {
    let timeToWaitBeforeShow = 1;
    let maxCount = 3;
    let daysToRepeat = 3;
    let count = 0;
    let mtime = new Date().getTime();
    let gpInviteCookie = {};

    if ($('.google-play-invite-js').length) {

        let deferNextInvite = function() {
            gpInviteCookie = {
                count: count + 1,
                mtime: mtime + (1000 * 60 * 60 * 24 * daysToRepeat)
            }
            Cookies.set('gpInviteCookie', gpInviteCookie, {expires: 365});
        }

        let showInvite = function() {
            $('.google-play-invite-js').append(app.templates['GooglePlayInvite']);
            let elm = $('.gp-invite');
            setTimeout(function () {
                elm.addClass('showed');
            }, timeToWaitBeforeShow * 1000);
            $('.gp-invite-close-js').on('click', function () {
                elm.removeClass('showed');
                setTimeout(function () {
                    elm.remove()
                }, 500);
                deferNextInvite();
            });
            $('.install-app-js').on('click', function (e) {
                e.preventDefault();
                deferNextInvite();
                location.href = $(this).attr('href');
            });
        }

        let gpInvite = Cookies.get('gpInviteCookie');
        if (!gpInvite) {
            count = 0;
            mtime = new Date().getTime();
            showInvite();
        } else {
            gpInvite = JSON.parse(gpInvite);
            count = gpInvite.count;
            mtime = gpInvite.mtime;
            if (count <= maxCount && new Date().getTime() > mtime + (1000 * 60 * 60 * 24 * daysToRepeat)) {
                mtime = new Date().getTime();
                showInvite();
            }
        }

    }
};
