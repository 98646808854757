export default function () {
    let wrapper = $('.social-widget-js');
    if (!wrapper.length) {
        return;
    }

    let removeSocialWidget = function(tabs, socialId) {
        tabs.each(function() {
            let target = $(this).data('target');
            if (target === socialId) {
                $(this).parent('li').remove();
                wrapper.find(target).remove();
            }
        });
    }

    let activateSocialWidget = function(tabs, socialId) {
        tabs.each(function() {
            let target = $(this).data('target');
            if (target === socialId) {
                $(this).parent('li').addClass('loaded');
                // $(target).addClass('loaded');
            }
        });
    }

    let triggerSocialTab = function(tabs, socialId) {
        tabs.each(function() {
            let target = $(this).data('target');
            let li = $(this).parent('li');
            if (target === socialId) {
                li.addClass('active');
                wrapper.find(target).addClass('active');
            } else {
                li.removeClass('active');
                wrapper.find(target).removeClass('active');
            }
        });
    }

    setTimeout(function() {
        wrapper.each(function() {
            let currentWrapper = $(this);
            let tabs = currentWrapper.find('.social-tab-js'); 

            if (!tabs.length) {
                return true;
            }

            tabs.each(function() {
                let target = $(this).data('target');
                if (!currentWrapper.find(target).length) {
                    return false;
                }
            });


            let urlGeo = "https://api.seeip.org/geoip";
            let urlVk = 'https://vk.com/js/api/openapi.js?116';
            let needLoadTwitter = false;

            $.ajax ({
                url: urlGeo,
                method: 'GET',
                dataType: 'json',
                timeout: 10000
            }).then(function(res) {
                if (!res){
                    console.log('Can\'t load data from https://api.seeip.org/geoip');
                    return;
                }
                if (!res['country_code']) {
                    console.log('Can\'t load data from https://api.seeip.org/geoip');
                    return;
                }
                return res;
            })
            .then(function(msg) {
                if (msg.country_code === 'UA') {
                    console.log('Country is UA - nees load Twitter!');
                    removeSocialWidget(tabs, '#vk-groups');
                    needLoadTwitter = true;
                } else {
                    let socialId = '#vk-groups';
                    return $.ajax({
                        url: urlVk,
                        dataType: "script",
                        timeout: 2 * 1000,
                        cache: true
                    })
                    .then(function() {
                        VK.Widgets.Group(
                            "vk-groups",
                            {mode: 0, width: "250", height: "300", color1: 'FFFFFF', color2: '2B587A', color3: '5B7FA6'},
                            97754394
                        );
                        activateSocialWidget(tabs, socialId);
                        triggerSocialTab(tabs, socialId);
                    })
                    .catch(function() {
                        removeSocialWidget(tabs, socialId);
                        console.log('Error loading VK widget');
                    });
                }
            })
            .then(function() {
                let socialId = '#fb-page';
                return $.ajax({
                    url: 'https://connect.facebook.net/ru_RU/sdk.js',
                    dataType: "script",
                    timeout: 2 * 1000,
                    cache: true
                }).then(function(res) {
                    FB.init({
                        appId: '345538108912807',
                        version: 'v11.0',
                        XFBML: 1,
                        autoLogAppEvents: true,
                    });
                    FB.XFBML.parse();
                    activateSocialWidget(tabs, socialId);
                    triggerSocialTab(tabs, socialId);
                    return true;
                })
                .catch(function() {
                    removeSocialWidget(tabs, socialId);
                    console.log('Error loading Facebook widget');
                });
            })
            .then(function() {
                tabs.each(function() {
                    $(this).on('click', function(e) {
                        e.preventDefault();
                        triggerSocialTab(tabs, $(this).data('target'));
                    });
                });
            })
            .catch(function() {
            console.log('Error loading social group widgets');
            })
            .always(function() {
                wrapper.removeClass('wbg');
            });
        });
    }, 7000);
}