export default function () {

  // let wrapper = $('.partner-insertion-dynamic-js');
  let res = {
    wrapper: $('.partner-insertion-dynamic-js'),
    csrf: window.app.csrf,

    init: function() {
      if (!this.wrapper.length) {
        return;
      }
      let _this = this;
      let data = {};
      data[_this.csrf.name] = _this.csrf.value;
      $.ajax({
        url: '/main/get_partners_news',
        method: 'POST',
        data: data,
        error: null,
      }).then(function(msg) {
        if (msg.error || !msg.html) {
          console.log('Error while getting Partners insertion');
          _this.wrapper.remove();
          return;
        }
        _this.wrapper.html(msg.html);
        if (app.LazyLoad) {
          app.LazyLoad.update();
        }

      }).catch(function() {
        console.log('Error getting Partners insertion');
        _this.wrapper.remove();
      }).always(function() {
        let cookie = window.Cookies.get(_this.csrf.name + '_cookie');
        if (cookie && cookie !== _this.csrf.value) {
          _this.csrf.value = cookie;
        }
      });

    },
  };

  res.init();
  return res;
}

