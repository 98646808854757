export default function () {
    let wrapper = $('.touch-scrollable-js');
    if (!wrapper.length) {
        return;
    }

    import (/*webpackChunkName: 'swiper'*/ '../SwiperSrc/swiper.esm')
    .then((asd) => {
      window.Swiper = asd.Swiper;
      const nav = asd.Navigation;
      window.Swiper.use([nav]);
    })
    .then(() => {
      app.swiperBlogInsertion = new Swiper('.touch-scrollable-js', {
        centeredSlides: false,
        wrapperClass: 'w-swiper-js',
        slideClass: 'w-swiper-slide-js',
        grabCursor: true,
        slidesPerView: 'auto',
        updateOnWindowResize: true,
      });
    });


};
