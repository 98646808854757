// import { data } from "autoprefixer";

export default function () {
    let wrappers = $('.blog-subscribe-js');
    if (!wrappers.length) {
        return;
    }

    let init = function(elm) {
      let reqUrl = new URL(location.origin + '/blogs/company_subscribe');
      let dataSubscribe = elm.data('subscribe');
      let dataSubscribed = elm.data('subscribed');

      if (!dataSubscribe) {
        let auth = $('.auth-js');
        if (!auth.length) {
          app.fn.alert({title: err.error, message: msg.error});
        } else {
          auth.first().click();
        }
        clearLoad(elm);
        return $.Deferred().resolve(false);
      }
      reqUrl.searchParams.set('cid', dataSubscribe);
      reqUrl.searchParams.set('action', dataSubscribed ? '0' : '1');
      return $.ajax({
          url: reqUrl.toString(),
          method: 'GET',
          data: {}
      }).then(function(msg) {
          if (msg.error) {
              app.fn.alert({title: err.error, message: msg.error});
          } else if (!msg.html) {
              app.fn.alert({title: err.error, message: err.err_format});
          } else {
              return msg;
          }
      });
  }

  let fixSize = function(elm) {
    elm.width(elm.width());
    elm.height(elm.height());
    elm.addClass('loading');
  }
  let clearLoad = function(elm) {
    elm.removeClass('loading');
    elm.removeAttr('style');
  }

  let replaceSubscribeBtns = function(elm, msg) {
    let dataAddClass = elm.data('add-class');
    let newClass = Math.random().toString(36).substring(2,15);
    elm.wrap('<div class="' + newClass + '"></div>');
    elm.replaceWith(msg.html);
    let newElm = $('.' + newClass).find('.blog-subscribe-js');
    if (dataAddClass) {
      newElm.addClass(dataAddClass);
      newElm.data('add-class', dataAddClass);
    } else {
      newElm.removeClass(dataAddClass);
    }
    newElm.unwrap();
    clickHandler(newElm);
  }

  let replaceOtherSubscribeBtns = function(elm, msg){
    $('.blog-subscribe-js[data-subscribe="' + elm.data('subscribe') + '"').each(function() {
      if ($(this).hasClass('loading')) {
        return true;
      }
      replaceSubscribeBtns($(this), msg);
    });
  }

  let clickHandler = function(elm) {
    elm.on('click', function(e) {
      e.preventDefault();
      let elm = $(this);
      fixSize(elm);
      init(elm)
        .then(function(msg) {
          if (msg) {
            replaceOtherSubscribeBtns(elm, msg);
            replaceSubscribeBtns(elm, msg);
          }
        })
        .always(function() {
          clearLoad(elm);
        });
    });
  }

  $.each(wrappers, function(i, wrapper) {
    clickHandler($(this));
  });

};
