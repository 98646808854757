// app.templates['login'] = '//inject:js/src/login/loginTemplate.html//endinject';
// app.templates['register'] = '//inject:js/src/login/registerTemplate.html//endinject';
// app.templates['remember'] = '//inject:js/src/login/rememberTemplate.html//endinject';

app.templates['login'] = require('./loginTemplate.html').default;
app.templates['register'] = require('./registerTemplate.html').default;
app.templates['remember'] = require('./rememberTemplate.html').default;


$('.auth-js').on('click', function(e) {
    e.preventDefault();
    app.fn.alert({
        title:'',
        message:app.templates.login,
        class: 'login'
    });
});

