$('select').selectmenu();


app.window.width = $(window).width();
app.window.height = $(window).height();

$(window).on('scroll', function() {
    $(window).trigger('immediateScrollWindow');
    app.immediateScroll = $(window).scrollTop();
    clearTimeout(window.tmp);
    
    app.scrollDirection = app.immediateScroll - app.scroll > 0 ? 'down' : 'up';

    app.scroll = app.immediateScroll;
    window.tmp = setTimeout(function waits() {
        if ($(window).scrollTop() === app.scroll) {
            $(window).trigger('scrollWindow');
        }
    }, 300);
});

$(window).on('resize', function() {

//console.log('Fired Resize...');
//console.log('app.window.width... ' + app.window.width);
//console.log('$(window).width()... ' + $(window).width());
//console.log('app.window.height... ' + app.window.height);
//console.log('$(window).height()... ' + $(window).height());

//preventing false event resize on mobile devices when scroll and address bar is hidding !!!
    if (app.window.width == $(window).width() || app.window.height == $(window).height()) {
    //    console.log('Preventing fail Resize...');
        return;
    }

    clearTimeout(window.tmpResize);
    app.window.width = $(window).width();
    app.window.height = $(window).height();
    window.tmpResize = setTimeout(function fnResize() {
        $(window).trigger('resizeWindow');
    }, 300);
});

$('body').on('click', '.open-js', function(e) {
    e.preventDefault();
    if (typeof($(this).data('href')) !== 'undefined') {
        location.href = $(this).data('href');
    }
});
 
 if (typeof(app.scrollToId) !== 'undefined') {
    if (app.window.isLoaded) {
         app.fn.scrollToElmId(app.scrollToId);
    } else {
        $(window).one('load', function() {
            app.fn.scrollToElmId(app.scrollToId);
        });
    }
 }
 
 $('body').on('click', '.hamburger-js', function() {
    var target = $($(this).data('target'));
    if (target.length) {
       app.fn.toggleSmallMenu($(this), target);
    }
 });
 
 $(window).on('resizeWindow', function() {
    $('.hamburger-js').each(function() {
        var hideOnResize = $(this).data('hideOnResize');
        if (hideOnResize == '1') {
            var target = $($(this).data('target'));
            if (target.length) {
                app.fn.hideSmallMenu($(this), target);
            }
        }
    });
 });
 
 //modify all links without rel
//  setTimeout(function() {
//   $("article a[target='_blank']").each(function(i, v) {
//     var relStr = $(v).attr('rel');
//     if (!relStr) {
//       $(v).attr(rel, 'noreferrer')
//     } else {
//       var newRelArr = relStr.split(' ');
//       if (newRelArr.indexOf('noreferrer') === -1) {
//         newRelArr.push('noreferrer');
//       }
//       var newRel = newRelArr.join(' ');
//       $(v).attr('rel', newRel);
//     }
//   });
//     // $("article a[target='_blank'][rel!='noreferrer']").attr('rel', 'noreferrer');
//  }, 100);
