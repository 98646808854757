app.fn.initFilterVar = function() {
    var mainBlock = $('.main.main-js');
    
    app['leftPanel'] = {
        offset: {
            top: parseInt(mainBlock.css('padding-top'))
        }
    };
};

var mainWrapper = $('.main');

app.fn.posLeftPanel = function() {
    var leftPanel = $('.left-panel');

    app.fn.initFilterVar();
    
    var burger = leftPanel.find('.hamburger-js');
    var target = $(burger.data('target'));
    if (
          leftPanel.find('input[type="checkbox"]:checked').length
          || burger.hasClass('is-active')
    ) {
      app.fn.showSmallMenu(burger, target);
    }
    if ($(window).scrollTop() > app.leftPanel.offset.top) {
      // if ($('.main-footer-js')[0].getBoundingClientRect().top - $(window).height() >= 0 ){
        if (mainWrapper[0].getBoundingClientRect().bottom - $(window).height() >= 0 ){
        leftPanel
          .removeClass('fixed-bottom')
          .addClass('fixed-top');
      } else {
        leftPanel
          .removeClass('fixed-top')
          .addClass('fixed-bottom');
      }

    } else {
      leftPanel.removeClass('fixed-top fixed-bottom');
    } 
}

app.fn.initFilter = function() {
    
    $(window).one('scrollWindow', function() {
        app.fn.posLeftPanel();
    });
    
    $(window).on('immediateScrollWindow resizeWindow', function() {
        app.fn.posLeftPanel();
    });

};

app.fn.initFilter();
