import touchScrollable from "../blogs/touchScrollable.js";
import subscribeToCompany from "../blogs/subscribeToCompany.js";

export default function () {
    let wrapper = $('.blog-at-main-js');
    if (!wrapper.length) {
        return;
    }

    wrapper.addClass('loading');

    let init = function() {
        let reqUrl = '/main/get_blog_part';
        return $.ajax({
            url: reqUrl.toString(),
            method: 'GET',
            data: {}
        }).then(function(msg) {
            if (msg.error) {
                app.fn.alert({title: err.error, message: msg.error});
            } else if (!msg.html) {
                app.fn.alert({title: err.error, message: err.err_format});
            } else {
                return msg;
            }
        });
    }
    
    init()
        .then(function(msg) {
            if (msg) {
                wrapper.find('.w-content').html(msg.html);
                touchScrollable();
                if (app.LazyLoad) {
                    app.LazyLoad.update();
                }
                subscribeToCompany();
            } else {
                wrapper.addClass('hidden');
            }
        })
        .always(function() {
            wrapper.removeClass('loading');
        });
}