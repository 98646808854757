// import (/* webpackChunkName: "style" */ '../../css/scss/style.scss');
// import (/* webpackChunkName: "style_tmp" */ '../../css/scss/style_tmp.scss');

// import (/* webpackChunkName: "js/build/vendors" */ '../jquery-3.2.1.min.js')
// .then(() => {
//     return require (/* webpackChunkName: "js/build/vendors" */ '../jquery-ui.min.js');
// })
// .then(() => {
//     return require (/* webpackChunkName: "js/build/vendors" */ '../plugins/swiper.js');
// })
// .then(() => {
//     return require (/* webpackChunkName: "js/build/vendors" */ '../plugins/lazyload.js');
// })
// .then(() => {
//     return require (/* webpackChunkName: "js/build/vendors" */ '../plugins/picturefill.js');
// })
// .then(() => {
//     console.log(jQuery);
// })
// ;


import googlePlayInvite from './googlePlayInvite/index.js';
import blogHomeTabs from './blogs/blogHomeTabs/index.js';
import userMenuTags from './blogs/blogHomeTabs/userMenuTags.js';
import touchScrollable from './blogs/touchScrollable.js';
import subscribeToCompany from './blogs/subscribeToCompany.js';
import blogInsertionAtMain from './main/blogInsertionAtMain.js';
import socialWidgets from './social_widgets/loadWidgets.js';
import PartnerInsertion from './main/PartnerInsertion.js';

export var initMainScript = function () {

    (function ($) {
        'use strict';

        require('./common/upload');  //$.fn.upl
        require('./common/commonFunctions');  //app.fn.waitElmExists
        require('./alert/main');

        require('./common/commonVars');
        require('./forms/formErrors');

        $(function () {
            require('./common/common');

            if ($('textarea.autosize-js').length) {
                require('./autosize/index');
            }
// app.fn.alert({title:'qwer', message:'1111111', classAnimation: 'from-left'});

//login,register
            if ($('.auth-js').length) {
                require('./login/loginOpener');
                require('./login/loginCommon');
            }

// //app.fn.alert({
// //    title:'',
// ////    message:app.templates.login,
// //    message:app.templates.register,
// //    class: 'login'
// //});
// //login,register


//main menu
            if ($('ul.main-menu').length) {
                require('./common/mainMenu');
            }
//main menu

//search window
            if ($('.search-overlay').length) {
                require('./common/searchWindow');
            }
//search window

//scroll to top
            if ($('.go-top-js').length) {
                require('./common/scrollToTop');
            }
//scroll to top

// //subscribe
            if ($('.subscribe-opener-js').length) {
                require('./subscribe/subscribeOpener');
            }

            require('./subscribe/subscribeCommon');

            blogInsertionAtMain();
            
//main slider
            if ($('.swiper-container a.w-news').length) {
                require('./main/slider');
            }
//main slider

//main show more button
            if ($('.show-more-js').length) {
                require('./common/showMoreAtMain');
            }
//main show more button

            if ($('.social-likes-inline-js').length || $('.social-share-js').length) {
                require('./socialLikes/getScript');
                if ($('.social-likes-inline-js').length) {
                    require('./socialLikes/inlineButtons');
                }
                if ($('.social-share-js').length) {
                    require('./socialLikes/sidedSocialLikes');
                }
            }

            require('./common/googleAnalitycs');

            // require('./social_widgets/widgets');

            if ($('.filter-rubrics-js').length) {
                require('./rubricsFilter/positioningBlock');
                require('./rubricsFilter/rubrics');
                app.fn.initRubrics();
            }

            if ($('.all-rubric-js').length) {
                require('./rubricsAll/index');
            }

            if ($('.w-comments-js').length) {
                require('./comments/index');
                require('./comments/commentAnswer');
            }

            if (!app.debug) {
                require('./sw/regSw');
            }

            require('./googleAnalitycs/getScript');

            googlePlayInvite();

            if ($('.w-left-block-js').length) {
                require('./rubricsFilter/positioningBlock');
            }

			      if (!app.debug) {
	            socialWidgets();
			      }

            blogHomeTabs();

            userMenuTags();

            touchScrollable();

            subscribeToCompany();

            PartnerInsertion();

            require('./common/windowLoad');     //must be last

        });
    })(jQuery)

};

app.fn.waitUntilLoad(['jQuery'], function () {
    initMainScript();
});

