export default function () {
    let wrapperTabs = $('.blog-company-home .tabs-js');

    if (!wrapperTabs.length) {
        return;
    }

    let tabsArr = $('.tab-js');
    let tabActive;
    let target;
    let currentUrl = new URL(window.location.href);
    let content = {};
    let domContent = $('section.tab-content');
    let cid = wrapperTabs.data('cid');
    let pages = {};
    let body = $("html, body");
    let scrollTo = 0;

    let initTabActive = function() {
        tabActive = $('.tab-js.active');
        if (!tabActive.length) {
            tabsArr[0] = tabActive;
        }
        tabActive.addClass('active');
        target = tabActive.data('target');
        pages[target] = '1';
        setTabContent(false);
    }

    let getTabContent = function(forceGet) {
        _fixHeight();
        domContent.html('<div class="loader"><img class="image-loading" src="/css/img/bx_loader.gif"/></div>')
        if (content[target] && !forceGet) {
            return new $.Deferred().resolve({html: content[target]});
        }

        let reqUrl = new URL(currentUrl.origin + '/blogs/get_home_content');
        reqUrl.search = currentUrl.search;
        reqUrl.searchParams.set('detail', target);
        reqUrl.searchParams.set('cid', cid);
        reqUrl.searchParams.set('page', pages[target]);
        return $.ajax({
            url: reqUrl.toString(),
            method: 'GET',
            data: {}
        }).then(function(msg) {
            if (msg.error) {
                app.fn.alert({title: err.error, message: msg.error});
            } else if (!msg.html) {
                app.fn.alert({title: err.error, message: err.err_format});
            } else {
                return msg;
            }
        });
    
    }

    let setCurrentUrl = function(dataTarget) {
        if (dataTarget === 'home') {
            currentUrl.searchParams.delete('detail')
        } else {
            currentUrl.searchParams.set('detail', dataTarget);
        }
        window.history.pushState({}, document.title, currentUrl.toString());
    }

    let setTabContent = function(forceGet) {
        getTabContent(forceGet)
        .then(function(msg) {
            if (msg) {
                domContent.html(msg.html);
                content[target] = msg.html;
                if (app.LazyLoad) {
                    app.LazyLoad.update();
                }
                initInnerLinks();
            }
        })
        .always(function() {
            _unFixHeight();
            body.stop().animate({scrollTop: scrollTo}, 'swing');
    });

    }

    let _fixHeight = function() {
        domContent.css({
            height: domContent.height() + "px"
        });
    }

    let _unFixHeight = function() {
        domContent.find('.loader').remove();
        domContent.removeAttr('style');
    }

    let initInnerLinks = function() {
        $('.go-tab-video-js').on('click', function(e) {
            e.preventDefault();
            $('.tab-js[data-target="video"]').click();
        });

        $('.go-tab-news-js').on('click', function(e) {
            e.preventDefault();
            $('.tab-js[data-target="news"]').click();
        });

        $('.paginator-js a').on('click', function(e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                return;
            }
            let pageUrl = new URL($(this).attr('href'));
            let pageParam = pageUrl.searchParams.get('page');
            if (pageParam) {
                pages[target] = pageParam;
            } else {
                pages[target] = '1';
            }
            scrollTo = $('.w-main-tabs').offset().top;
            setTabContent(true);
        });
    }

    initTabActive();

    $('.tab-js').on('click', function() {
        tabActive.removeClass('active');            
        $(this).addClass('active');
        tabActive = $(this);
        target = tabActive.data('target');
        if (!pages[target]) {
            pages[target] = '1';
        }
        scrollTo = $('.w-main-tabs').offset().top;
        setCurrentUrl(target);
        setTabContent(false);
    });


};
