// app.templates['subscribe'] = '//inject:js/src/subscribe/subscribeTemplate.html//endinject'
app.templates['subscribe'] = require('./subscribeTemplate.html').default;

var subs = $('.subscribe-opener-js');
subs.on('click', function() {
    app.fn.alert({
        title:'Подпишись на “Где Трафик”',
        message: app.templates['subscribe'],
        classAnimation: 'from-left',
        class: 'subscribe'
    });
});
